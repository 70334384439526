import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image'
import './Navigation.css'

const loadPage=(page) =>{
  window.location.href=page
  window.location.reload()
}

export default class Navigation extends Component {
  render() {
    return (
      <div className='nav-header'>
        <Navbar className='fixed-top' collapseOnSelect bg="white" expand="lg">
          <Navbar.Brand>
            <Link to="/">
              <Image src={require('../../assets/SASEPro_Logo.png')} className='logo' fluid />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/#">Home</Nav.Link>
              <Nav.Link href="#events">Events</Nav.Link>
              <Nav.Link href="#about">About Us</Nav.Link>
              <Nav.Link onClick={() => loadPage('/#get-involved')}>Get Involved</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
