import React, { Component } from "react";
import ImageCarousel from "./ImageCarousel";
import "./Home.css";
import { GoogleSpreadsheet } from "google-spreadsheet";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const doc = new GoogleSpreadsheet(
  "1Ri9n6QvCzwtLN2PRWRjx2KswZYd3TEOBtDqhYrAXT5Y"
);

export default class Home extends Component {
  initSheets = async () => {
    await doc.useServiceAccountAuth(require("./service-act-creds.json"));
    await doc.loadInfo(); // loads document properties and worksheets
    const upcoming = doc.sheetsByIndex[0]; // or use doc.sheetsById[id]
    await upcoming.loadCells();
    const rows = await upcoming.getRows();

    let tempUpcoming = rows.map((row) => {
      return { ...row, coord: [row.long, row.lat] };
    });

    this.setState(() => ({
      upcoming: tempUpcoming,
    }));
  };

  async componentDidMount() {
    await this.initSheets();
  }

  constructor(props) {
    super(props);
    this.state = {
      upcoming: null,
    };
  }

  render() {
    return (
      <div className="home">
        <ImageCarousel />
        <div className="container">
          <Row>
            <Col className="carouselcol mission-statement" sm="8">
              <section>
                <header>
                  <h1>
                    Society of Asian Scientists and Engineers Boston Chapter
                  </h1>
                </header>
                <p>
                  The Society of Asian Scientists and Engineers (SASE) is a
                  national professional organization that aims to help Asian
                  heritage scientific and engineering professionals achieve
                  their full potential. SASE Professional Boston chapter will
                  give working professionals the opportunity to build their
                  network, continue to develop leadership and professional
                  skills, and celebrate diversity in the workplace.
                </p>
                <h4>Mission:</h4>
                <p>
                  <ul>
                    <li>
                      Prepare Asian heritage scientists and engineers for
                      success in the global business world.
                    </li>
                    <li>Celebrate diversity in the workplace.</li>
                    <li>
                      Provide opportunities for members to make contributions to
                      their local communities.
                    </li>
                  </ul>
                </p>
              </section>
            </Col>
            {!this.state.upcoming && (
              <Col>
                <section>
                  <Spinner className="spinner" animation="border" />
                </section>
              </Col>
            )}
            {}
            {this.state.upcoming && (
              <Col className="carouselcol upcoming-events" sm="4">
                <section>
                  <h2 className="underline">Upcoming Events</h2>
                  {this.state.upcoming.length === 0 && (
                    <h3>Check back later!</h3>
                  )}
                  {this.state.upcoming.map((event, index) => (
                    <div>
                      <Row>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={event.facebook}
                        >
                          <h3 className="eventName">{event.name}</h3>
                        </a>
                      </Row>
                      <p>{event.address}</p>
                      <p>
                        {event.date}, {event.time}
                      </p>
                    </div>
                  ))}
                </section>
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
  }
}
