let prior = [
    { 'year': 2019, 'members' : [
            {'name': 'Aaron Silva', 'position': 'President', 'linkedIn': 'https://www.linkedin.com/in/silvaa14/'},
            {'name': 'Lucy Lin', 'position': 'Vice President', 'linkedIn': 'https://www.linkedin.com/in/lucylin27/'},
            {'name': 'Albert Sze', 'position': 'Treasurer', 'linkedIn': 'https://www.linkedin.com/in/albert-sze-b9a2127a/'},
            {'name': 'Robin Li', 'position': 'Collegiate Outreach', 'linkedIn':'https://www.linkedin.com/in/rxl7906/'},
            {'name': 'Diana Chen', 'position': 'Membership Chair', 'linkedIn': 'https://www.linkedin.com/in/itsdianachen/'},
            {'name': 'Pia Muyot', 'position': 'Programming Director', 'linkedIn': 'https://www.linkedin.com/in/pia-muyot/'},
            {'name': 'Aric Lu', 'position': 'Logistics Chair', 'linkedIn': 'https://www.linkedin.com/in/aric-lu/'},
            {'name': 'Jose Escobar', 'position': 'Director of Community Service', 'linkedIn': 'https://www.linkedin.com/in/escobar2/'},
            {'name': 'David Hsieh', 'position': 'Fitness Director', 'linkedIn': 'https://www.linkedin.com/in/david-hsieh-9a7081161/'},
            {'name': 'Jason Liu', 'position': 'Marketing Intern', 'linkedIn': 'https://www.linkedin.com/in/jason-liu-ba97a0154/'}
        ]
    },
    { 'year': 2018, 'members' : [
            {'name': 'Aaron Silva', 'position': 'President', 'linkedIn': 'https://www.linkedin.com/in/silvaa14/'},
            {'name': 'Poling Yeung', 'position': 'Vice President', 'linkedIn': 'https://www.linkedin.com/in/lucylin27/'},
            {'name': 'Albert Sze', 'position': 'Treasurer', 'linkedIn': 'https://www.linkedin.com/in/albert-sze-b9a2127a/'},
            {'name': 'Arthi Vezhavendan', 'position': 'VP of Programming', 'linkedIn': 'https://www.linkedin.com/in/arthi-vezhavendan-3417465a/'},
            {'name': 'Lucy Lin', 'position': 'VP of Marketing', 'linkedIn': 'https://www.linkedin.com/in/lucylin27/'},
            {'name': 'Diana Chen', 'position': 'Outreach Director', 'linkedIn': 'https://www.linkedin.com/in/itsdianachen/'},
            {'name': 'Serena Zhou', 'position': 'VP of Internal Communications', 'linkedIn': 'https://www.linkedin.com/in/jingweizhou/'},
        ]
    },
    {'year': 2017, 'members' : [
            {'name': 'George Luo', 'position': 'President', 'linkedIn': 'https://www.linkedin.com/in/george-luo-38852450/'},
            {'name': 'Aaron Silva', 'position': 'Vice President', 'linkedIn': 'https://www.linkedin.com/in/silvaa14/'},
            {'name': 'Albert Sze', 'position': 'Board Member', 'linkedIn': 'https://www.linkedin.com/in/albert-sze-b9a2127a/'},
            {'name': 'Arthi Vezhavendan', 'position': 'Board Member', 'linkedIn': 'https://www.linkedin.com/in/arthi-vezhavendan-3417465a/'},
            {'name': 'Serena Zhou', 'position': 'Board Member', 'linkedIn': 'https://www.linkedin.com/in/jingweizhou/'},
            {'name': 'Poling Yeung', 'position': 'Board Member', 'linkedIn': 'https://www.linkedin.com/in/silvaa14/'},
        ]
    },
    { 'year': 2016, 'members' : [
            {'name': 'George Luo', 'position': 'President', 'linkedIn': 'https://www.linkedin.com/in/george-luo-38852450/'},
            {'name': 'Stephanie Williams', 'position': 'Marketing Director', 'linkedIn': 'https://www.linkedin.com/in/lucylin27/'},
            {'name': 'Aaron Silva', 'position': 'Board Member', 'linkedIn': 'https://www.linkedin.com/in/silvaa14/'},
            {'name': 'Albert Sze', 'position': 'Board Member', 'linkedIn': 'https://www.linkedin.com/in/albert-sze-b9a2127a/'},
            {'name': 'Arthi Vezhavendan', 'position': 'Board Member', 'linkedIn': 'https://www.linkedin.com/in/arthi-vezhavendan-3417465a/'},
            {'name': 'Serena Zhou', 'position': 'Board Member', 'linkedIn': 'https://www.linkedin.com/in/jingweizhou/'},
        ]
    }
];

export default prior;