import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import './Register.css'
import Image from "react-bootstrap/Image";

export default class Register extends Component {
    render() {
        return (
            <div className="register">
                <section>
                    <h1>Get Involved</h1>
                    <p>We have deployed a newsletter in which we share upcoming events that we host & job opportunities!
                      Please fill out this form if you would like to be kept up to date with our events!</p>
                    <Button className="btn" size="lg"
                        href="https://saseconnect.us20.list-manage.com/subscribe?u=1b1a9c52b9013e2004f032850&id=c3280cba5d">Signup</Button>

                    <a className='qrcode'
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://forms.gle/8bqfBJ5qEMttcQYc6"
                    >
                        <Image
                            src={require("../../assets/Sign In Form QR Code.png")}
                            className="logo qrcode-image"
                            fluid
                        />
                    </a>

                    <p className='caption'>Events Sign-in Form</p>

                    <h3>Facebook Page</h3>
                    <p> Follow us here for current events.</p>
                    <Button className="btn" size="lg" href="https://www.facebook.com/SASEBostonPro/">Facebook</Button>
                </section>
                <section>
                    <div className="fb-page" data-href="https://www.facebook.com/SASEBostonPro/" data-tabs="timeline"
                         data-height="" data-small-header="false" data-adapt-container-width="true" data-width="500"
                        data-hide-cover="false" data-show-facepile="true">
                    </div>
                </section>
            </div>
        );
    }
}
