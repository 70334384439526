import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import './About.css';


export default class PastMemberContainer extends Component {
    render () {
        return (
            <Row className='past-member-container'>
                <a target="_blank" rel="noopener noreferrer" href={this.props.person.linkedIn}><Image src={require('../../assets/linkedin.svg')} className='logo' fluid /></a>
                <p><span className='name'>{this.props.person.name}</span> - {this.props.person.position}</p>
            </Row>
        )
    }
}
