import Image from 'react-bootstrap/Image';
import React, { Component } from 'react';
import './Footer.css'

export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/SASEBostonPro/"><Image src={require('../../assets/facebook.svg')} className='logo' fluid /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/groups/4872001/"><Image src={require('../../assets/linkedin.svg')} className='logo' fluid /></a>

                </div>

                <p>
                    Copyright: {(new Date().getFullYear())} Society of Asian Scientists and Engineers (SASE) Professional Boston Chapter. All Rights Reserved.
                </p>

                <div>
                    <a target="_blank" rel="noopener noreferrer" href="https://conference.saseconnect.org/privacy-policy">Privacy Policy</a>
                    &nbsp;|&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href="https://conference.saseconnect.org/rules-of-behavior">Rules of Behavior</a>
                    &nbsp;|&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href="mailto:boston.pro@saseconnect.org">boston.pro@saseconnect.org</a>
                </div>
            </div>
        )
    }
}
