import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import BioContainer from './BioContainer';
import PastMemberContainer from './PastMemberContainer';
import bios from './content/bios';
import priorList from './content/prior';

export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bios: bios,
            priorList: priorList.sort((a, b) => { return b.year - a.year })
        }
    }

    render() {
        return (
            <div className='about'>
                <h1>SASEPro Boston Board Members</h1>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className='toggle-text'>
                            Current Board
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0" className='current-board-accordian'>
                            <Container>
                                { this.state.bios.map((bio, index) =>
                                    <BioContainer person={bio} key={index} />
                                )}
                            </Container>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                { this.state.priorList.map((prior) =>
                <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className='toggle-text'>
                        {prior.year}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Container>
                            { prior.members.map((person) => {
                                return (
                                    <PastMemberContainer person={person} />
                                )})}
                        </Container>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
                )}

            </div>
        );
    }
}
