

const bios = [
    {
        'name': 'Aaron Silva',
        'linkedIn': 'https://www.linkedin.com/in/silvaa14/',
        'bio': 'Aaron is the current President of SASEPro Boston. He works as a software engineer at Boston Scientific, and is a part of the digital health team. During his free time, he loves wood working and cycling! His dream is finishing an Iron Man!'
    },
    {
        'name': 'Lucy Lin',
        'linkedIn': 'https://www.linkedin.com/in/lucylin27/',
        'bio': 'Lucy serves as the VP of SASEPro Boston. She works at Proctor & Gamble and is based out of the Gillette site in South Boston! She is currently preparing for her first marathon and love puns, coffee shops, and rock climbing! '
    },
    {
        'name': 'Pia Muyot',
        'linkedIn': 'https://www.linkedin.com/in/pia-muyot/',
        'bio': 'Pia is the programming director for SASEPro Boston. She works as a senior scientist at Compass Therapeutics in Cambridge, MA. Outside of work and SASE, Pia enjoys crossing things off her bucket list and rooting for the Chicago Cubs.'
    },
    {
        'name': 'Aric Lu',
        'linkedIn': 'https://www.linkedin.com/in/aric-lu/',
        'bio': 'Aric is our logistics chair and intern manager. He graduated from the University of Delaware in 2018 with a degree in Electrical Engineering. Currently, he is a PhD student at Harvard University studying Bioengineering. In his free time, Aric enjoys running, cooking, and spending time with friends.'
    },
    {
        'name': 'Jose Escobar',
        'linkedIn': 'https://www.linkedin.com/in/escobar2/',
        'bio': 'Jose is the director of community service. Jose works as a manufacturing engineer at GE Aviation in Lynn, MA. He’s a NYC native and a graduate of the University at Buffalo. Outside of SASE, he enjoys traveling, cycling, jogging and meeting new people!'
    },
    {
        'name': 'Diana Chen',
        'linkedIn': 'https://www.linkedin.com/in/itsdianachen/',
        'bio': 'Diana is membership chair and works as a software engineer at Medtronic. She graduated from Boston University with a biomedical engineering degree. Diana loves robotics and passionate about helping people and saving lives. She manifests that through her career in medical robotics. '
    },
    {
        'name': 'David Hsieh',
        'linkedIn': 'https://www.linkedin.com/in/david-hsieh-9a7081161/',
        'bio': 'David is the fitness director and currently works as a manufacturing engineer at Scully Signal Company in Wilmington, MA. He gradated from Purdue University with a degree in Mechanical Engineering. He loves playing sports, doing CrossFit, and outdoor activities in general. He\'s also a big fan of craft beer.'
    },
    {
        'name': 'Meghna Kuppuraju',
        'linkedIn':'https://www.linkedin.com/in/meghna-kuppuraju/',
        'bio': 'Meghna serves as the Events Facilitator for Boston SASEPro. She currently works as at CRISPR Therapeutics in Cambridge, MA. In her free time, Meghna enjoys attempting to bake and going for walks around Boston!'
    },
    {
        'name': 'Derek Tran',
        'linkedIn':'https://www.linkedin.com/in/derek-tran16/',
        'bio': 'Derek is a the collegiate relations chair and graduated from Northeastern University in 2019. He is currently a mechanical engineer at Symmons Industries, working on the water management platform. He enjoys playing volleyball, trying new outdoor activities and recently started his plant collection. He loves traveling and is hoping to visit more places outside of the country!'
    },
    {
        'name': 'Eliza Sabilla',
        'linkedIn':'https://www.linkedin.com/in/cherylle-eliza-sabilla-389059194/',
        'bio': 'Eliza is an incoming sophomore at Worcester Polytechnic Institute (WPI) majoring in Industrial Engineering. She is currently the Events Coordinator for the SASE chapter and she is passionate about the work that she does for this organization. She is an avid traveller and she loves to play tennis. Meet her at the courts!'
    },
    {
        'name': 'Allen Zou',
        'linkedIn':'https://www.linkedin.com/in/allen-zou/',
        'bio': 'Allen is a rising junior at Boston University studying Computer Engineering. At BU, he is the corporate relations chair for the SASE chapter and a member of Theta Tau Psi Delta. He also loves singing, biking, bouldering, and finding new things to bake at home!'
    }
];

export default bios
