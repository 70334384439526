import React, { Component } from "react";
import "./Events.css";
import PastEventsCarousel from "./PastEventsCarousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { GoogleSpreadsheet } from "google-spreadsheet";
import Spinner from "react-bootstrap/Spinner";

const doc = new GoogleSpreadsheet(
  "1Ri9n6QvCzwtLN2PRWRjx2KswZYd3TEOBtDqhYrAXT5Y"
);
let map;
const mapboxgl = window.mapboxgl;

const initMarker = (marker, type, index) => {
  let el = document.createElement("div");
  el.id = type + index;
  el.classList.add(type);
  // make a marker for each feature and add to the map
  if (!isNaN(marker.coord[0])) {
    new mapboxgl.Marker(el)
      .setLngLat(marker.coord)
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML(
            "<h3>" +
              marker.type +
              "</h3><p> " +
              marker.name +
              "</p ><p> " +
              marker.address +
              "</p>"
          )
      )
      .addTo(map);
  }
};

export default class Events extends Component {
  initMap() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYWxsZW56MTEyMCIsImEiOiJja2JneTRhb3YwMDE0MzVucmQ5cHJxOWhiIn0.PIK8vgLwjuIxqm9VChhI-g";
    map = new mapboxgl.Map({
      container: "map", // container id
      style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
      center: [-71.087574, 42.351231], // starting position [lng, lat]
      zoom: 12, // starting zoom
    });

    this.state.past.forEach(function (marker, index) {
      // create a HTML element for each feature
      initMarker(marker, "marker-past", index);
    });

    // add markers to map
    this.state.upcoming.forEach(function (marker, index) {
      // create a HTML element for each feature
      initMarker(marker, "marker-upcoming", index);
    });
  }

  initSheets = async () => {
    await doc.useServiceAccountAuth(require("./service-act-creds.json"));
    await doc.loadInfo(); // loads document properties and worksheets
    const upcoming = doc.sheetsByIndex[0]; // or use doc.sheetsById[id]
    const past = doc.sheetsByIndex[1]; // or use doc.sheetsById[id]
    await upcoming.loadCells();
    await past.loadCells();
    const rows = await upcoming.getRows();
    const rows2 = await past.getRows();

    let tempUpcoming = rows.map((row) => {
      return { ...row, coord: [row.long, row.lat] };
    });

    let tempPast = rows2.map((row) => {
      return { ...row, coord: [row.long, row.lat] };
    });

    this.setState(() => ({
      upcoming: tempUpcoming,
      past: tempPast,
    }));
  };

  async componentDidMount() {
    await this.initSheets();
    this.initMap();
  }

  constructor(props) {
    super(props);
    this.state = {
      upcoming: null,
      past: null,
    };
  }

  openPop(event, index) {
    if (map) {
      map.flyTo({
        center: event.coord,
        speed: 0.5,
      });
      document.getElementById("marker-upcoming" + index).click();
    }
  }

  render() {
    return (
      <div className="events">
        {!this.state.upcoming && (
          <Spinner className="spinner" animation="border" />
        )}
        {this.state.upcoming && (
          <div className="container">
            <Row>
              <Col className="carouselcol" sm>
                <h2 className="underline">Upcoming Events</h2>
                {this.state.upcoming.length === 0 && <h3>Check back later!</h3>}
                {this.state.upcoming.map((event, index) => (
                  <div>
                    <br />
                    <Row>
                      <h2
                        className="eventName"
                        onClick={() => this.openPop(event, index)}
                      >
                        {event.name}
                      </h2>

                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={event.facebook}
                      >
                        <Image
                          src={require("../../assets/facebook.svg")}
                          className="logo"
                          fluid
                        />
                      </a>
                    </Row>
                    <p>{event.address}</p>
                    <p>
                      {event.date}, {event.time}
                    </p>
                  </div>
                ))}
              </Col>
              <Col className="carouselcol" sm>
                <Row>
                  <div id="map"></div>
                </Row>
                <Row className="legend">
                  <Col sm="6">
                    <Image
                      src={require(`./SASEPro_Logo.png`)}
                      roundedCircle
                      className="legendLogo"
                      height="40px"
                    />
                    <h5 className="legendText"> - Past Events</h5>
                  </Col>
                  <Col sm="6">
                    <Image
                      src={require(`./SASEPro_Logo2.png`)}
                      roundedCircle
                      className="legendLogo"
                      height="40px"
                    />
                    <h5 className="legendText"> - Upcoming Events</h5>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}

        <div>
          <div className="banner">
            <h2 className="banner-header">Past Event Highlights</h2>
          </div>
          <div className="container">
            <Row>
              {/* <Col className="carouselcol" sm>
                                <h2>Upcoming Events</h2>
                            </Col> */}
              <Col className="carouselcol" sm>
                {/* <h2>Past Events Highlights</h2> */}
                <PastEventsCarousel />
              </Col>
            </Row>
          </div>
        </div>
        {!this.state.past && <Spinner className="spinner" animation="border" />}
        {this.state.past && (
          <Row className="past-events-list">
            <Col sm="4">
              <h3>Social</h3>
              {this.state.past
                .filter((event) => event.type === "Social")
                .map((event, index) => (
                  <div>
                    <a href={event.facebook}>{event.name}</a>
                  </div>
                ))}
            </Col>
            <Col sm="4">
              <h3>Professional</h3>
              {this.state.past
                .filter((event) => event.type === "Professional")
                .map((event, index) => (
                  <div>
                    <a href={event.facebook}>{event.name}</a>
                  </div>
                ))}
            </Col>
            <Col sm="4">
              <h3>Volunteering</h3>
              {this.state.past
                .filter((event) => event.type === "Volunteering")
                .map((event, index) => (
                  <div>
                    <a href={event.facebook}>{event.name}</a>
                  </div>
                ))}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}
