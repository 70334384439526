import React, { Component } from 'react';
import './App.css';
import Routes from './routes'
import { HashRouter as Router } from "react-router-dom";
import Navigation from './components/Navigation/Navigation'
import Footer from './components/Footer/Footer'

export default class App extends Component {
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL} hashType="noslash">
        <Navigation />
        <Routes />
        <Footer />
      </Router>
    );
  }
}
