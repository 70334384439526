import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import './About.css'

export default class BioContainer extends Component {
    render () {
        return (
            <Row className='container'>
                <Col xs={6} md={4} fluid>
                    <Image src={require(`../../assets/headshots/${this.props.person.name}.jpg`)} roundedCircle height='200px'/>
                </Col>
                <Col xs='auto' md={6} className='bio-container'>
                    <Row><h3>{this.props.person.name}</h3>
                    <a target="_blank" rel="noopener noreferrer" href={this.props.person.linkedIn}><Image src={require('../../assets/linkedin.svg')} className='logo' fluid /></a>
                    </Row>
                    <Row> <p>{this.props.person.bio}</p></Row>
                </Col>
            </Row>
        )
    }
}
