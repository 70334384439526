import React, {Component} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from "react-bootstrap/Image";

const descriptions = [
    { 'heading': 'Epic', 'caption': 'SASE Talks: Microsoft NERD Center' },
    { 'heading': 'Community', 'caption': 'Summer Picnic' },
    { 'heading': 'Scrumptious', 'caption': 'Korean BBQ with SASE Boston' },
    { 'heading': 'Unwind', 'caption': 'SASE Social: Grill and Chill' },
    { 'heading': 'Network', 'caption': 'Time Out Market with our special guests: SASE Marketing Committee!' },
    { 'heading': 'Adventure', 'caption': 'SASE Foliage Hike' },
    { 'heading': 'Cultural', 'caption': 'Japan Festival' },
    { 'heading': 'Socialize', 'caption': 'SASE Social: Felipe\'s' },
    { 'heading': 'Experience', 'caption': 'SASE Social: Cambridge Brewing Company' },
];

export default class ImageCarousel extends Component {
    render () {
        return (
          <Carousel>
              {
                  descriptions.map((description, index) =>
                    <Carousel.Item>
                        <Image src={require(`../../assets/image_carousel/img_${index + 1}.jpg`)} className="d-block w-100"/>
                        <Carousel.Caption>
                            <h3>{description.heading}</h3>
                            <p>{description.caption}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                  )
              }
          </Carousel>
        );
    }
}

