import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";

const descriptions = [
  {
    heading: "Professional: National Conference 2019",
    caption: "Some great workshops and plenty of networking!",
  },
  {
    heading: "Professional: SASE Talks",
    caption: "Share topics that you're passionate about!",
  },
  {
    heading: "Professional: Novice Woodworking Presentation",
    caption: "Practice your presentations!",
  },
  {
    heading: "Professional: Microsoft NERD Center",
    caption: "Sitdown with SASE!",
  },
  { heading: "Social: Quarantine Hangout", caption: "Make Zoom Meetings Fun!" },
  {
    heading: "Social: Race 4 Research 5k",
    caption: "Group Photo with the Racers",
  },
  {
    heading: "Social: Foliage Hike",
    caption: "Amazing Views with Amazing People",
  },
  { heading: "Social: Grill and Chill", caption: "Candid Barbeque Picture" },
];

export default class PastEventsCarousel extends Component {
  render() {
    return (
      <Carousel>
        {descriptions.map((description, index) => (
          <Carousel.Item>
            <Image
              src={require(`../../assets/past_events_carousel/img_${
                index + 1
              }.jpg`)}
              className="d-block w-100"
            />
            <Carousel.Caption>
              <h3>{description.heading}</h3>
              <p>{description.caption}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}
