import React from "react";
import { Route, Switch } from 'react-router-dom';
import Home from './components/Home/Home'
import Register from './components/Register/Register'
import About from './components/About/About'
import Events from './components/Events/Events'

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/get-involved" component={Register} />
      <Route path="/about" component={About} />
      <Route path="/events" component={Events} />
      <Route path='*' component={Home} />
    </Switch>
  );
}

export default Routes;
